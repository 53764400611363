import gql from 'graphql-tag';

// fragments

export const FullTransactionFields = gql`
  fragment FullTransactionFields on Transaction {
    id
    type
    date
    status
    rejectionMessages
    note
    assets {
      __typename
      ... on PlayerAsset {
        id
        previousDesignation
        previousSalary
        previousFranchise
        designation {
          id
          name
          order
        }
        salary
        to {
          id
          name
          logo
          abbr
        }
        from {
          id
          name
          logo
          abbr
        }
        contract {
          id
          salary
        }
        player {
          id
          name
          position
          team
          years
          contract {
            id
            salary
            designation {
              id
              name
              order
            }
            franchise {
              id
              name
              logo
              abbr
            }
          }
        }
      }
      ... on AdjustmentAsset {
        id
        salary
        note
        previousDesignation
        previousSalary
        previousFranchise
        adjustmenPlayer: player {
          id
          name
        }
        adjustment {
          id
        }
        to {
          id
          name
          logo
          abbr
        }
      }
      ... on DraftPickAsset {
        id
        to {
          id
          name
          logo
          abbr
        }
        from {
          id
          name
          logo
          abbr
        }
        draftPick {
          id
          pick
          round
          year
          franchise {
            id
            name
            logo
            abbr
          }
          originalFranchise {
            id
            name
            logo
            abbr
          }
        }
        draftedPlayer {
          name
          position
          team
          years
          contract {
            id
            salary
            designation {
              id
              name
              order
            }
            franchise {
              id
              name
              logo
              abbr
            }
          }
        }
      }
    }
  }
`;

// Queries

export const LOGIN_USER = gql`
  mutation LoginUser($email: String!, $password: String!) {
    login(input: { email: $email, password: $password }) {
      token
      errors
    }
  }
`

export const CREATE_TRANSACTION = gql`
  mutation CreateTransaction($input: CreateTransactionInput!) {
    createTransaction(input: $input) {
      transaction {
        id
      }
      errors
    }
  }
`

export const APPROVE_TRANSACTION = gql`
  mutation ApproveTransaction($id:ID!) {
    approveTransaction(input: {id: $id}) {
      transaction {
        id
      }
      errors
    }
  }
`

export const DECLINE_TRANSACTION = gql`
  mutation DeclineTransaction($id:ID!) {
    declineTransaction(input: {id: $id}) {
      transaction {
        id
      }
      errors
    }
  }
`

export const UPDATE_TRANSACTION_STATUS = gql`
  mutation UpdateTransactionStatus($id:ID!, $status:TransactionStatus!) {
    updateTransactionStatus(input: {id: $id, status: $status}) {
      transaction {
        ...FullTransactionFields
      }
      errors
    }
  }
  ${FullTransactionFields}
`

export const DEACTIVATE_ADJUSTMENT = gql`
  mutation DeactivateAdjustment($id:ID!) {
    deactivateAdjustment(input: {id: $id}) {
      adjustment {
        id
      }
      errors
    }
  }
`

export const SKIP_DRAFT_PICK = gql`
  mutation SkipDraftPick($input: SkipDraftPickInput!) {
    skipDraftPick(input: $input) {
      draftPick {
        id
      }
      errors
    }
  }
`

export const FAVORITE_PLAYER = gql`
  mutation FavoritePlayer($input: FavoritePlayerInput!) {
    favoritePlayer(input: $input) {
      player {
        id
      }
      errors
    }
  }
`

export const FAVORITES_QUERY = gql`
  query Favorites {
    favorites {
      id
    }
  }
`

export const PLAYERS_QUERY = gql`
  query AllPlayers($search:String, $position:String, $offset:Int, $limit:Int $status:String, $contract:String, $favorites:Boolean, $rookies:Boolean, $sortOrder:String) {
    allPlayers(search: $search, position: $position, offset: $offset, limit: $limit, status: $status, contract: $contract, favorites: $favorites, rookies: $rookies, sortOrder: $sortOrder) {
      total
      players {
        id
        name
        position
        team
        years
        contract {
          id
          salary
          designation {
            id
            name
            order
          }
          franchise {
            id
            name
            logo
            abbr
          }
        }
      }
    }
  }
`

export const ALL_FRANCHISES_QUERY = gql`
  query Franchises {
    franchises {
      id
      name
      motto
      logo
      abbr
      order
      phone
      availableCapSpace
      availableRosterSpots
    }
  }
`

export const ALL_DESIGNATIONS_QUERY = gql`
  query Designations {
    designations {
      id
      name
      order
    }
  }
`

export const ALL_YEARS_QUERY = gql`
  query Years {
    years
  }
`

export const ALL_DRAFT_PICKS_QUERY = gql`
  query DraftPicks($onlyAvailable:Boolean!, $franchiseId:ID, $year:Int) {
    draftPicks(onlyAvailable:$onlyAvailable, franchiseId:$franchiseId, year:$year) {
      id
      pick
      round
      year
      skipped
      salary
      designation {
        id
        name
        order
      }
      player {
        id
        name
        position
        team
        years
        contract {
          id
          salary
          franchise {
            id
            name
            logo
            abbr
          }
          designation {
            id
            name
            order
          }
        }
      }
      franchise {
        id
        name
        logo
        abbr
      }
      originalFranchise {
        id
        name
        logo
        abbr
      }
    }
  }
`

export const FRANCHISE_QUERY = gql`
  query Franchise($id:ID!) {
    franchise(id: $id) {
      id
      name
      motto
      logo
      color
      abbr
      phone
      order
      availableCapSpace
      availableRosterSpots
      owner {
        id
        name
        email
      }
      contracts {
        id
        player {
          id
          name
          position
        }
        designation {
          id
          name
          order
        }
        salary
      }
      adjustments {
        id
        amount
        note
      }
      availableDraftPicks {
        id
        pick
        round
        year
        salary
        franchise {
          id
          name
          logo
          abbr
        }
        originalFranchise {
          id
          name
          logo
          abbr
        }
      }
    }
  }
`

export const SAVE_FRANCHISE_QUERY = gql`
  mutation SaveFranchise($input: SaveFranchiseInput!) {
    saveFranchise(input: $input) {
      franchise {
        id
        name
        motto
        logo
        color
        abbr
        phone
      }
      errors
    }
  }
`

export const PLAYER_QUERY = gql`
  query Player($id:ID!, $includeHistory: Boolean = false) {
    player(id: $id) {
      __typename
      id
      name
      position
      team
      years
      contract {
        id
        salary
        designation {
          id
          name
          order
        }
        franchise {
          id
          name
          logo
          abbr
        }
      }
      history @include(if: $includeHistory) {
        id
        type
        date
        status
        note
        rejectionMessages
        assets {
          __typename
          ... on PlayerAsset {
            id
            previousDesignation
            previousSalary
            previousFranchise
            designation {
              id
              name
              order
            }
            salary
            to {
              id
              name
              logo
              abbr
            }
            from {
              id
              name
              logo
              abbr
            }
            contract {
              id
              salary
            }
            player {
              id
              name
              position
              team
              years
              contract {
                id
                salary
                designation {
                  id
                  name
                  order
                }
                franchise {
                  id
                  name
                  logo
                  abbr
                }
              }
            }
          }
          ... on AdjustmentAsset {
            id
            salary
            note
            previousDesignation
            previousSalary
            previousFranchise
            adjustmenPlayer: player {
              id
              name
            }
            adjustment {
              id
            }
            to {
              id
              name
              logo
              abbr
            }
          }
          ... on DraftPickAsset {
            id
            to {
              id
              name
              logo
              abbr
            }
            from {
              id
              name
              logo
              abbr
            }
            draftPick {
              id
              pick
              round
              year
              franchise {
                id
                name
                logo
                abbr
              }
              originalFranchise {
                id
                name
                logo
                abbr
              }
            }
            draftedPlayer {
              name
              position
              team
              years
              contract {
                id
                salary
                designation {
                  id
                  name
                  order
                }
                franchise {
                  id
                  name
                  logo
                  abbr
                }
              }
            }
          }
        }
      }
    }
  }
`

export const ADJUSTMENT_QUERY = gql`
  query Adjustment($id:ID!, $includeHistory: Boolean = false) {
    adjustment(id: $id) {
      __typename
      id
      amount
      note
      history @include(if: $includeHistory) {
        id
        type
        date
        status
        note
        rejectionMessages
        assets {
          __typename
          ... on PlayerAsset {
            id
            previousDesignation
            previousSalary
            previousFranchise
            designation {
              id
              name
              order
            }
            salary
            to {
              id
              name
              logo
              abbr
            }
            from {
              id
              name
              logo
              abbr
            }
            contract {
              id
              salary
            }
            player {
              id
              name
              position
              team
              years
              contract {
                id
                salary
                designation {
                  id
                  name
                  order
                }
                franchise {
                  id
                  name
                  logo
                  abbr
                }
              }
            }
          }
          ... on AdjustmentAsset {
            id
            salary
            note
            previousDesignation
            previousSalary
            previousFranchise
            adjustmenPlayer: player {
              id
              name
            }
            adjustment {
              id
            }
            to {
              id
              name
              logo
              abbr
            }
          }
          ... on DraftPickAsset {
            id
            to {
              id
              name
              logo
              abbr
            }
            from {
              id
              name
              logo
              abbr
            }
            draftPick {
              id
              pick
              round
              year
              franchise {
                id
                name
                logo
                abbr
              }
              originalFranchise {
                id
                name
                logo
                abbr
              }
            }
            draftedPlayer {
              name
              position
              team
              years
              contract {
                id
                salary
                designation {
                  id
                  name
                  order
                }
                franchise {
                  id
                  name
                  logo
                  abbr
                }
              }
            }
          }
        }
      }
    }
  }
`

export const DRAFT_PICK_QUERY = gql`
  query DraftPick($id:ID!, $includeHistory: Boolean = false) {
    draftPick(id: $id) {
      __typename
      id
      pick
      round
      year
      franchise {
        id
        name
        logo
        abbr
      }
      originalFranchise {
        id
        name
        logo
        abbr
      }
      history @include(if: $includeHistory) {
        id
        type
        date
        status
        note
        rejectionMessages
        assets {
          __typename
          ... on PlayerAsset {
            id
            previousDesignation
            previousSalary
            previousFranchise
            designation {
              id
              name
              order
            }
            salary
            to {
              id
              name
              logo
              abbr
            }
            from {
              id
              name
              logo
              abbr
            }
            contract {
              id
              salary
            }
            player {
              id
              name
              position
              team
              years
              contract {
                id
                salary
                designation {
                  id
                  name
                  order
                }
                franchise {
                  id
                  name
                  logo
                  abbr
                }
              }
            }
          }
          ... on AdjustmentAsset {
            id
            salary
            note
            previousDesignation
            previousSalary
            previousFranchise
            adjustmenPlayer: player {
              id
              name
            }
            adjustment {
              id
            }
            to {
              id
              name
              logo
              abbr
            }
          }
          ... on DraftPickAsset {
            id
            to {
              id
              name
              logo
              abbr
            }
            from {
              id
              name
              logo
              abbr
            }
            draftPick {
              id
              pick
              round
              year
              franchise {
                id
                name
                logo
                abbr
              }
              originalFranchise {
                id
                name
                logo
                abbr
              }
            }
            draftedPlayer {
              name
              position
              team
              years
              contract {
                id
                salary
                designation {
                  id
                  name
                  order
                }
                franchise {
                  id
                  name
                  logo
                  abbr
                }
              }
            }
          }
        }
      }
    }
  }
`

export const TRANSACTION_QUERY = gql`
  query Transaction($id:ID!) {
    transaction(id: $id) {
      ...FullTransactionFields
    }
  }
  ${FullTransactionFields}
`

export const OFFSEASON_NEGOTIATION_QUERY = gql`
  query OffseasonNegotiation($franchiseId: ID!, $season: Int!) {
    offseasonNegotiation(franchiseId: $franchiseId, season: $season) {
      ...FullTransactionFields
    }
  }
  ${FullTransactionFields}
`

export const CREATE_TRANSACTION_ASSET = gql`
  mutation CreateTransactionAsset($input: CreateTransactionAssetInput!) {
    createTransactionAsset(input: $input) {
      transaction {
        ...FullTransactionFields
      }
      errors
    }
  }
  ${FullTransactionFields}
`

export const DELETE_TRANSACTION_ASSET = gql`
  mutation DeleteTransactionAsset($input: DeleteTransactionAssetInput!) {
    deleteTransactionAsset(input: $input) {
      transaction {
        ...FullTransactionFields
      }
      errors
    }
  }
  ${FullTransactionFields}
`

export const TRANSACTIONS_SEARCH = gql`
  query TransactionSearch($type: TransactionKind, $franchiseId:Int, $offset:Int, $status:String) {
    allTransactions(type: $type, franchiseId: $franchiseId, offset: $offset, status: $status) {
      total
      transactions {
        id
        type
        date
        note
        status
        rejectionMessages
        assets {
          __typename
          ... on AdjustmentAsset {
            id
            type
            salary
            note
            previousDesignation
            previousSalary
            previousFranchise
            adjustmenPlayer: player {
              id
              name
            }
            adjustment {
              id
            }
            to {
              id
              name
              logo
              abbr
            }
          }
          ... on DraftPickAsset {
            id
            to {
              id
              name
              logo
              abbr
            }
            from {
              id
              name
              logo
              abbr
            }
            draftPick {
              id
              pick
              round
              year
              franchise {
                id
                name
                logo
                abbr
              }
              originalFranchise {
                id
                name
                logo
                abbr
              }
            }
            draftedPlayer {
              name
              position
              team
              years
              contract {
                id
                salary
                designation {
                  id
                  name
                  order
                }
                franchise {
                  id
                  name
                  logo
                  abbr
                }
              }
            }
          }
          ... on PlayerAsset {
            id
            type
            salary
            previousDesignation
            previousSalary
            previousFranchise
            to {
              id
              name
              logo
              abbr
            }
            from {
              id
              name
              logo
              abbr
            }
            contract {
              id
              salary
            }
            designation {
              id
              name
              order
            }
            player {
              id
              name
              position
              team
              years
              contract {
                id
                salary
                designation {
                  id
                  name
                  order
                }
                franchise {
                  id
                  name
                  logo
                  abbr
                }
              }
            }
          }
        }
      }
    }
  }
`

export const DATA_QUERY = gql`
  query Data($chart: String!) {
    data(chart: $chart) {
      chart
      data
      colors
      keys
    }
  }
`